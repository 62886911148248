import React from "react";
import './Heroarea.css'


const Heroarea =()=>{

    return (
      <div className="hero-header mt5 pt2 ml6 pl5   ">
          <div className="welcome mb0">
            <h1>Emmanuel.</h1>
          
          </div>
          
          <div className="text pl3 pr6 mr6">
            <span>I love building... stuff.  </span>
          </div>
      </div>
    )
}

export default Heroarea;